import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  spacing: factor => `${0.5 * factor}rem`, // (Bootstrap strategy)
  palette: {
    primary: { main: "#1b2a65" },
    secondary: { main: "#f96e67" },
  },
  typography: {
    fontFamily: "GoldPlay, Arial",
  },
  overrides: {
    MuiButton: {
      root: {
        padding: "1rem 4rem",
      },
    },
  },
})

export default theme
